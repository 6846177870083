<template>
  <div class="report-history-embed">
    <div ref="report-history" class="p-2">
      <div v-if="!cycle_loading && !stock_loading">
        <div v-if="loaded">
          <div v-if="selectedTab === $t('wq.wq')">
            <water-quality-analysis hide-chart hide-action :default-type="type" @loaded="sendMessage" :timediff="timediff" :date-label="dateLabel"/>
          </div>
          <div v-else-if="selectedTab === $t('warehouse.product')">
            <products-analysis hide-chart hide-action :default-type="type" @loaded="sendMessage" :date-label="dateLabel"/>
          </div>
          <div v-else-if="selectedTab === $t('feed_tray.feed_tray')">
            <feed-tray-analysis hide-chart hide-action @loaded="sendMessage" :date-label="dateLabel"/>
          </div>
        </div>
      </div>
    </div>

    <div v-if="sent" class="sent"></div>

  </div>
</template>

<script>
import {mapState} from "vuex";
import {toPng} from 'html-to-image';
import {CometChat} from "@cometchat-pro/chat";

let download = require('downloadjs')

export default {
  name: "ReportHistoryEmbed",
  components: {
    ProductsAnalysis: () => import("@/components/Farm/Daily/ProductsAnalysis"),
    FeedTrayAnalysis: () => import("@/components/Farm/Daily/FeedTrayAnalysis"),
    WaterQualityAnalysis: () => import("@/components/Farm/Daily/WaterQualityAnalysis"),
  },
  data() {
    return {
      selectedTab: this.$t('wq.wq'),
      selectedBlock: null,

      cycle_loading: false,
      stock_loading: false,

      loaded: false,

      img: null,

      messageTimeout: null,

      sent:false,
    }
  },
  computed: {
    timediff () {
      return parseInt(this.$route.query.timediff)
    },
    canViewWqs() {
      return this.$store.getters["farm/canViewWqs"]
    },
    farm_id() {
      return parseInt(this.$route.params.farm_id)
    },
    selectedDate: {
      get() {
        return this.$store.state.farm.analysis.date
      },
      set(value) {
        this.$store.dispatch('farm/updateSelectedFarmStatusDate', value)
        this.refreshData()
      },
    },
    selectedFarm() {
      return this.$store.state.farm.selectedFarm
    },
    selectedPonds: {
      get() {
        return this.$store.state.farm.overview.ponds
      },
      set() {
      }
    },
    defaultTab() {
      switch (this.$route.query.tab) {
        case 'water-quality':
          return this.$t('wq.wq')
        case 'product':
          return this.$t('warehouse.product')
        case 'feed-tray':
          return this.$t('feed_tray.feed_tray')
        case 'water-change':
          return this.$t('water_change.water_change')
      }
      return null
    },

    tag() {
      switch (this.$route.query.tab) {
        case 'water-quality':
          return 'KualitasAir'
        case 'product':
          return 'Product'
        case 'feed-tray':
          return 'Anco'
        case 'water-change':
          return 'GantiAir'
      }
      return null
    },

    defaultBlock() {
      return this.$route.query.block_id
    },

    type() {
      return this.$route.query.type || ''
    },

    date() {
      return parseInt(this.$route.query.date || new Date().getTime())
    },

    uid() {
      return this.$route.query.pu_id
    },

    guid() {
      return `sgara${this.farm_id}`
    },

    ...mapState('farm', [
      'analysis'
    ]),

    ...mapState('user', [
      'cometChatUser',
    ]),

    dateLabel() {
      return this.$dateFormat(this.$addMinute(this.date, this.timediff))
    },
  },
  beforeDestroy() {
    this.$store.dispatch('farm/updateSelectedFarmStatusDate', this.selectedDate)
  },
  created() {
    this.$loading()
    this.chatLogin().then(() => {
      this.selectedDate = new Date(this.date)
      if (this.defaultTab) this.selectedTab = this.defaultTab

      this.cycle_loading = true
      this.stock_loading = true

      Promise.all(
          [
            ...this.selectedFarm.ponds.map(e => {
              return this.$store.dispatch('farm/getCycleOverview', {pond: e})
            }),
            ...this.selectedFarm.ponds.map(e => {
              return this.$store.dispatch('farm/getStockOverview', e)
            })
          ]
      ).then(res => {
        if (this.selectedFarm.blocks && this.selectedFarm.blocks.length) {
          this.selectedBlock = this.selectedFarm.blocks.find(e => e.block_id === this.defaultBlock) || this.selectedFarm.blocks[0]
        }

        this.refreshData()
      }).finally(() => {
        this.cycle_loading = false
        this.stock_loading = false
        this.$loading(false)
      })
    }).catch(() => {
      this.sent = true
      this.$loading(false)
    })
  },
  methods: {
    refreshData() {
      this.loaded = false

      let date = this.$timestamp(this.analysis.date)

      let ponds = []

      if (this.selectedBlock) {
        ponds = this._.cloneDeep(this.selectedFarm.ponds.filter(e => e.block_id === this.selectedBlock.block_id)).map(e => {
          let cycle = e.cycles.find(f => {
            return date >= f.start_date && (f.end_date === 0 || date <= f.end_date)
          })
          e.selected_cycle = cycle
          return e
        })
      }

      this.$store.commit('farm/UPDATE_OVERVIEW_PONDS', ponds)

      this.$nextTick(() => {
        this.$store.dispatch('farm/refreshOverview')
        this.loaded = true
      })
    },

    sendMessage() {
      if (this.messageTimeout) clearTimeout(this.messageTimeout)
      this.messageTimeout = setTimeout(() => {
        toPng(document.querySelector('.report-history-embed .b-table .table')).then(url => {
          let file = this.$dataURLtoFile(url, 'example.png')

          let receiverID = this.guid;
          let messageType = CometChat.MESSAGE_TYPE.IMAGE;
          let receiverType = CometChat.RECEIVER_TYPE.GROUP;
          let mediaMessage = new CometChat.MediaMessage(receiverID, file, messageType, receiverType);

          let messageText = `<b>${this.cometChatUser.name}</b>` + " baru saja menginput data " + this.defaultTab + " tanggal " + this.dateLabel;

          mediaMessage.setTags([this.tag])
          mediaMessage.setCaption(messageText)

          CometChat.sendMediaMessage(mediaMessage).then(
              message => {
                this.sent = true
                console.log("Media message sent successfully", message);
              }, error => {
                console.log("Media message sending failed with error", error);
              }
          );

        }).catch(err => {
          console.log(err)
        })
      }, 1000)
    },

    chatLogin() {
      return new Promise((resolve, reject) => {
        this.$store.commit('user/UPDATE_COMET_CHAT_STATUS')
        this.$store.commit('user/UPDATE_COMET_CHAT_USER')
        CometChat.login(this.uid, process.env.VUE_APP_COMETCHAT_APP_AUTH_KEY)
            .then(async res => {
              this.$store.commit('user/UPDATE_COMET_CHAT_STATUS', true)
              this.$store.commit('user/UPDATE_COMET_CHAT_USER', res)
              resolve(res)
            })
            .catch(err => {
              reject(err)
              // if (err.code === "ERR_UID_NOT_FOUND") this.chatCreateUser(this.user)
            })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.report-history-embed {
  ::v-deep {
    .b-table .table-wrapper.has-sticky-header {
      overflow: unset !important;
      max-height: unset !important;
      max-width: unset !important;
    }
  }
}
</style>
